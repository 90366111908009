<template>
  <div class="account-data-page">
    <portal to="page-name">Conta do paciente</portal>
    <div class="loading loading-lg" v-if="loading" />
    <template v-else>
      <div class="columns" v-if="header">
        <div class="column col-4 col-md-6 col-sm-12 form-group">
          <label class="form-label">Nome do paciente</label>
          <span class="text-bold h6">
          {{ form.patient.name }}
        </span>
        </div>
        <div class="column col-2 col-md-6 col-sm-12 form-group">
          <label class="form-label">Idade</label>
          <span class="text-bold h6">
          {{ form.patient.birthDate | dateOld('short') }}
        </span>
        </div>
        <div class="column col-2 col-md-6 col-sm-12 form-group">
          <label class="form-label">Sexo</label>
          <span class="text-bold h6">
          {{ form.patient.gender === 'female' ? 'Feminino' : 'Masculino' }}
        </span>
        </div>
        <div class="column col-4 col-md-6 col-sm-12 form-group">
          <label class="form-label">Local atual</label>
          <span class="text-bold h6">
            {{ form.place.name }} <small class="text-bold">({{ form.place.bed.name }})</small>
          </span>
        </div>
      </div>
      <div class="bg-gray p-2 mb-1">
        <div class="columns">
          <div class="column col-3 col-md-6 col-sm-12 form-group">
            <small>Status</small>
            <div class="text-bold text-primary">
              {{ accountStatus.getName(form.status) }}
            </div>
          </div>
          <div class="column col-3 col-md-6 col-sm-12 form-group">
            <small>Código</small>
            <div class="text-bold text-primary">{{ form.code }}</div>
          </div>
          <div class="column col-3 col-md-4 col-sm-12 form-group">
            <small>Data abertura</small>
            <div class="text-bold text-primary">
              {{ form.startDate | date('datetime') }}
            </div>
          </div>
          <div class="column col-3 col-md-4 col-sm-12 text-right form-group">
            <small>Valor total</small>
            <div class="text-bold text-primary">{{ total | currency }}</div>
          </div>
        </div>
        <div class="text-right">
          <button class="btn btn-icon btn-icon-left btn-sm btn-gray"
                  v-if="!isFinished" @click="openHospitalDischarge">
            <fa-icon :icon="['fal', 'usd-circle']" />Realizar alta do paciente
          </button>
        </div>
      </div>
      <div class="h6">Despesas</div>
      <div class="mb-2">
        <button class="btn btn-icon btn-icon-left btn-sm mr-1"
                :class="filters.show ? 'btn-warning' : 'btn-gray'"
                @click="openFilters" v-if="hasExpenses || true">
          <fa-icon :icon="['fal', 'filter']" />Filtros
        </button>
        <button class="btn btn-icon btn-icon-left btn-sm"
                :class="expense.show ? 'btn-warning' : 'btn-primary'"
                v-if="!isFinished" @click="openExpense">
          <fa-icon :icon="['fal', expense.show ? 'times' : 'plus']" />Adicionar despesa
        </button>
      </div>
      <div class="group-items" v-if="filters.show">
        <div class="columns">
          <div class="column col-2 col-md-12 col-sm-12 form-group">
            <label class="form-label">Tipo</label>
            <select id="filter-type" class="form-select"
                    v-model="filters.type">
              <option value="">Todos os status</option>
              <option v-for="(text, value) in type.types"
                      :value="value" :key="value">{{ text }}</option>
            </select>
          </div>
          <div class="column col-2 col-md-12 col-sm-12 form-group">
            <label class="form-label">Status</label>
            <select id="filter-status" class="form-select"
                    v-model="filters.status">
              <option value="">Todos os status</option>
              <option v-for="(text, value) in status.statuses"
                      :value="value" :key="value">{{ text }}</option>
            </select>
          </div>
          <div class="column col-3 col-md-12 col-sm-12 form-group">
            <label class="form-label">Convênio</label>
            <select id="filter-insurance" class="form-select"
                    v-model="filters.insurancePlanId">
              <option value="">Todos os convênios</option>
              <option v-for="(item, i) in insurances"
                      :value="item.id" :key="i">{{ item.name }}</option>
            </select>
          </div>
          <div class="column form-group">
            <label class="form-label">Pesquisar</label>
            <div class="input-group">
              <input type="text" id="filter-search" class="form-input"
                     v-model="filters.search"
                     placeholder="Nome ou parte do nome da despesa...">
              <button class="btn btn-neutral btn-action input-group-btn btn-icon"
                      tabindex="-1">
                <fa-icon :icon="['fal', 'search']"/>
              </button>
            </div>
          </div>
          <div class="column col-auto mb-2 col-sm-12 form-group"
               style="display: flex; align-items: flex-end">
            <button class="btn btn-primary btn-icon btn-icon-left btn-block"
                    :class="{loading: loadingExpenseList}"
                    :disabled="loadingExpenseList"
                    @click="loadExpenses">
              <fa-icon :icon="['fal', 'filter']"/>Filtrar
            </button>
          </div>
        </div>
      </div>
      <div class="group-items" v-if="expense.show">
        <div class="columns">
          <div class="column col-3 form-group"
               :class="{'has-error': $v.expense.insurancePlanId.$error}">
            <label class="form-label">Convênio</label>
            <select id="expense-insurance" class="form-select"
                    v-model="expense.insurancePlanId"
                    :disabled="hasExpense"
                    @blur="$v.expense.insurancePlanId.$touch()">
              <option v-for="(item, i) in filteredInsurances"
                      :value="item.id" :key="i">{{ item.name }}</option>
            </select>
          </div>
          <div class="column col-3 form-group"
               :class="{'has-error': $v.expense.type.$error}">
            <label class="form-label">Tipo</label>
            <select id="expense-type" class="form-select"
                    v-model="expense.type"
                    :disabled="hasExpense"
                    @blur="$v.expense.type.$touch()" v-focus>
              <option v-for="(text, value) in types"
                      :value="value" :key="value">{{ text }}</option>
            </select>
          </div>
          <div class="column col-6 form-group"
               :class="{'has-error': $v.expense.professionalId.$error}">
            <label class="form-label">Profissional</label>
            <select id="expense-professional" class="form-select"
                    v-model="expense.professionalId"
                    :disabled="hasExpense"
                    @blur="$v.expense.professionalId.$touch()">
              <option value="">[Selecione um profissional]</option>
              <option v-for="(item, i) in professionals"
                      :value="item.id" :key="i">
                {{ item.name }} ({{ item.specialty.name }})
              </option>
            </select>
          </div>
          <div class="column form-group"
               :class="{'has-error': $v.expense.item.$error}">
            <label class="form-label">Descrição</label>
            <dx-autocomplete
              v-model="expense.item"
              :source="findExpense"
              :disabled="!!(expense.item && expense.item.id)"
              label="name"
              track-by="id"
              @input="setExpense"
              @blur="$v.expense.item.$touch()"
              placeholder="Informe o código ou nome da despesa..."
              :debounce="800"
              input-id="expense-item">
              <button slot="action"
                      v-if="!!(expense.item && expense.item.id)"
                      class="btn btn-action input-group-btn btn-icon btn-gray"
                      @click="clearExpense(false)"
                      tabindex="-1">
                <fa-icon :icon="['fal', 'times']"></fa-icon>
              </button>
              <button slot="action" v-else
                      class="btn btn-action input-group-btn btn-icon btn-neutral"
                      tabindex="-1">
                <fa-icon :icon="['fal', 'search']"></fa-icon>
              </button>
              <template v-slot="{ item }">
                <a>
                  <fa-icon :icon="['fal', 'file-medical-alt']" class="text-primary"/>
                  <span class="ml-1">{{ item.code | tuss }} - {{ item.name }}
                  <span v-if="item.professional && item.professional.specialty"> ({{
                      item.professional.specialty.name }})
                  </span>
                </span>
                </a>
              </template>
            </dx-autocomplete>
          </div>
          <div class="column col-2 form-group"
               :class="{'has-error': $v.expense.quantity.$error}">
            <label class="form-label">Quantidade</label>
            <dx-input-number class="form-input" :precision="3"
                             v-model="expense.quantity"
                             @change="calcTotal"
                             @blur="$v.expense.quantity.$touch()" />
          </div>
          <div class="column col-1 form-group"
               :class="{'has-error': $v.expense.factor.$error}">
            <label class="form-label">Fator</label>
            <dx-input-number class="form-input" :precision="2"
                             v-model="expense.factor"
                             @change="calcTotal"
                             @blur="$v.expense.factor.$touch()" />
          </div>
          <div class="column col-2 form-group"
               :class="{'has-error': $v.expense.total.$error}">
            <label class="form-label">Total</label>
            <dx-input-number class="form-input" :precision="2"
                             v-model="expense.total"
                             :disabled="!expense.particularValue"
                             @blur="$v.expense.total.$touch()" />
          </div>
          <div class="column col-auto form-group mb-2"
               style="display: flex; align-items: flex-end">
            <button class="btn btn-icon btn-icon-left btn-primary"
                    :class="{loading: expense.loading}"
                    :disabled="expense.loading"
                    @click="addExpense">
              <fa-icon :icon="['fal', 'plus']" />Adicionar
            </button>
          </div>
        </div>
      </div>
      <div class="empty" v-if="!hasExpenses">
        <div class="empty-icon">
          <fa-icon :icon="['fal', 'file-invoice-dollar']" size="3x"></fa-icon>
        </div>
        <p class="empty-title h5">Despesas</p>
        <p class="empty-subtitle">
          Nenhuma despesa encontrada. Verifique os filtros e tente novamente
        </p>
      </div>
      <table class="table table-striped table-hover" v-else>
        <thead>
        <tr>
          <th>#</th>
          <th>Data / Status</th>
          <th>Tipo / Despesa</th>
          <th>Convênio / Profissional</th>
          <th class="text-center">Quantidade</th>
          <th class="text-right">Total</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, i) in form.items" :key="i">
          <td>{{ i + 1 }}</td>
          <td>
            {{ item.date | date('datetime') }}
            <div>{{ status.getName(item.status) }}</div>
          </td>
          <td>
            {{ type.getName(item.type) }}
            <div class="text-bold text-primary">
              <span v-if="item.expense.code">{{ item.expense.code | tuss }} - </span>
              {{ item.expense.name }}
            </div>
          </td>
          <td>
            {{ item.insurance.name }}
            <div>{{ item.professional ? item.professional.name : '-' }}</div>
          </td>
          <td class="text-center">{{ item.quantity }}</td>
          <td class="text-right">{{ item.total | currency }}</td>
          <td class="text-right">
            <button class="btn btn-icon btn-action btn-sm btn-error"
                    :disabled="expense.deleting"
                    @click="removeExpense(item.id, i)">
              <fa-icon :icon="['fal', 'times']" />
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </template>
    <hospital-discharge-modal
      v-if="form.id && hospitalDischargeModalShow"
      :id="form.id"
      :show="hospitalDischargeModalShow"
      @saved="savedHospitalDischarge"
      @close="closeHospitalDischarge"
    />
  </div>
</template>

<script>
import * as accountStatus from 'src/data/patient-account-statuses';
import * as status from 'src/data/patient-account-item-statuses';
import * as type from 'src/data/patient-account-item-types';
import { mergeFrom } from '@/helpers/object';
import { types } from 'src/data/patient-account-item-types';
import { required } from 'vuelidate/lib/validators';
import moment from 'moment';
import formMixin from '../../../mixins/form';
import HospitalDischargeModal from '../../attendance/components/modals/HospitalDischarge.vue';

export default {
  props: {
    id: {
      type: String,
    },
    header: {
      type: Boolean,
      default: true,
    },
  },
  mixins: [formMixin],
  components: {
    HospitalDischargeModal,
  },
  data() {
    return {
      path: '/patient-accounts',
      loading: false,
      loadingExpenseList: false,
      accountStatus,
      status,
      type,
      types,
      insurances: [],
      professionals: [],
      filters: {
        show: false,
        type: '',
        status: '',
        insurancePlanId: '',
        search: '',
      },
      expense: {
        show: false,
        loading: false,
        deleting: false,
        item: null,
        type: 'procedure',
        insurancePlanId: '',
        professionalId: '',
        quantity: '',
        factor: '',
        insuranceValue: '',
        particularValue: '',
        total: '',
      },
      hospitalDischargeModalShow: false,
      form: this.blankForm(),
    };
  },
  mounted() {
    this.loadInsurances();
    this.loadProfessionals();
    this.load();
  },
  computed: {
    filteredInsurances() {
      return this.insurances
        .filter((item) => {
          if (item.type === 'particular') {
            return true;
          }
          if (this.form.insurance.plan.id) {
            return this.form.insurance.plan.id === item.id;
          }
          return false;
        });
    },
    hasExpenses() {
      return this.form.items.length > 0;
    },
    isFinished() {
      return this.form.status === 'finished';
    },
    total() {
      let total = 0;
      total = this.form.items
        // .filter(({ insurance }) => insurance.type === 'particular')
        .reduce((a, b) => a + Number(b.total), 0);
      return total;
    },
    hasExpense() {
      return this.expense.item && this.expense.item.id;
    },
  },
  validations() {
    return {
      expense: {
        type: { required },
        insurancePlanId: { required },
        item: { required },
        professionalId: { required },
        quantity: { required },
        factor: { required },
        total: { required },
      },
    };
  },
  methods: {
    load() {
      this.loading = true;
      return this.$http.get(`${this.path}/${this.id}`)
        .then(({ data }) => {
          this.form = mergeFrom(this.blankForm(), data);
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    loadInsurances() {
      const params = {
        limit: 0,
        active: true,
      };
      return this.$http.get('/insurance-plans', { params })
        .then(({ data }) => {
          this.insurances = data.items.map(item => ({
            id: item.plan.id,
            type: item.type,
            name: item.customName,
          }));
        })
        .catch(() => {});
    },
    loadProfessionals() {
      const params = {
        limit: 0,
        active: true,
      };
      return this.$http.get('/professionals', { params })
        .then(({ data }) => {
          data.items.forEach((item) => {
            item.professional.specialties.forEach((specialty) => {
              this.professionals.push({
                id: item.id,
                name: item.name,
                specialty,
              });
            });
          });
        })
        .catch(() => {});
    },
    loadExpenses() {
      this.loadingExpenseList = true;

      const params = {
        limit: 0,
      };

      if (this.filters.type) {
        params.type = this.filters.type;
      }

      if (this.filters.status) {
        params.status = this.filters.status;
      }

      if (this.filters.insurancePlanId) {
        params.insurancePlanId = this.filters.insurancePlanId;
      }

      if (this.filters.search) {
        params.search = this.filters.search;
      }

      return this.$http.get(`${this.path}/${this.id}/expenses`, { params })
        .then(({ data }) => {
          this.form.items = data;
        })
        .catch(() => {})
        .finally(() => {
          this.loadingExpenseList = false;
        });
    },
    openExpense() {
      this.clearExpense(true);
      this.filters.show = false;
      this.expense.insurancePlanId = this.form.insurance.plan.id;
      this.expense.show = !this.expense.show;
    },
    openFilters() {
      this.expense.show = false;
      this.filters.insurancePlanId = this.form.insurance.plan.id;
      this.filters.show = !this.filters.show;
    },
    openHospitalDischarge() {
      this.hospitalDischargeModalShow = true;
    },
    closeHospitalDischarge() {
      this.hospitalDischargeModalShow = false;
    },
    savedHospitalDischarge(data) {
      this.hospitalDischargeModalShow = false;
      if (data && data.status === 'finished') {
        this.form.status = 'finished';
        this.$emit('savedAccount', true);
      }
    },
    findExpense(search) {
      const params = {
        search,
        planId: this.expense.insurancePlanId,
        limit: 30,
        offset: 0,
      };

      if (this.expense.professionalId) {
        params.professionalId = this.expense.professionalId;
      }

      return this.$http.get('/expense-insurances', { params })
        .then(({ data }) => {
          if (data.items.length === 0) {
            return [{
              id: null,
              name: 'Despesa não encontrado neste convênio',
            }];
          }
          return data.items;
        });
    },
    setExpense(data) {
      if (data) {
        this.expense.quantity = 1;
        this.expense.factor = 1;
        this.expense.factor = 1;
        this.expense.insuranceValue = data.values.insurance;
        this.expense.particularValue = data.values.particular;
        this.calcTotal();
      }
    },
    clearExpense(all = false) {
      this.expense.item = null;
      this.expense.quantity = '';
      this.expense.factor = '';
      this.expense.insuranceValue = '';
      this.expense.particularValue = '';
      this.expense.total = '';
      if (all) {
        this.expense.type = 'procedure';
        this.expense.professionalId = '';
        this.$v.expense.$reset();
      }
    },
    addExpense() {
      this.$v.expense.$touch();
      if (this.$v.expense.$error) {
        return;
      }

      this.expense.loading = true;

      const insurance = this.insurances
        .find(({ id }) => id === this.expense.insurancePlanId);

      const professional = this.professionals
        .find(({ id }) => id === this.expense.professionalId);

      const expenseData = {
        id: '',
        date: moment().format('YYYY-MM-DD[T]HH:mm'),
        type: this.expense.type,
        status: 'open',
        insurance: {
          id: insurance.id,
          name: insurance.name,
          record: this.form.insurance.record,
          validity: this.form.insurance.validity,
        },
        professional,
        expense: {
          id: this.expense.item.id,
          code: this.expense.item.code,
          name: this.expense.item.name,
        },
        quantity: this.expense.quantity,
        factor: this.expense.factor,
        particularValue: this.expense.particularValue,
        insuranceValue: this.expense.insuranceValue,
        total: this.expense.total,
      };

      this.$http.post(`/patient-accounts/${this.id}/expenses`, expenseData)
        .then(({ data }) => {
          expenseData.id = data.id;
          this.form.items.push(expenseData);
          this.clearExpense(true);
          this.$nextTick(() => {
            document.getElementById('expense-type').focus();
          });
        })
        .catch(() => {
          this.$toast.show('Ocorreu um erro. Tente novamente!', { type: 'error' });
        })
        .finally(() => {
          this.expense.loading = false;
        });
    },
    removeExpense(id, i) {
      this.$dialog.show('', {
        html:
          '<div class="text-center">'
          + '<h5 class="text-center">Atenção!</h5>'
          + '<div>Deseja realmente excluir este registro?</div>'
          + '</div>',
        buttons: [
          {
            label: 'Não',
            classes: '',
          }, {
            label: 'Sim',
            classes: 'btn-primary btn-error ml-2',
            click: (close) => {
              if (!this.expense.deleting) {
                this.expense.deleting = true;
                this.$http.delete(`/patient-accounts/${this.id}/expenses/${id}`)
                  .then(() => {
                    this.form.items.splice(i, 1);
                  })
                  .catch(() => {
                    this.$toast.show('Ocorreu um erro. Tente novamente!', { type: 'error' });
                  })
                  .finally(() => {
                    this.expense.deleting = false;
                    close();
                  });
              }
            },
          },
        ],
      });
    },
    calcTotal() {
      const particular = this.expense.particularValue || 0;
      const insurance = this.expense.insuranceValue || 0;
      const qty = this.expense.quantity || 1;
      const factor = this.expense.factor || 1;
      this.expense.total = ((particular + insurance) * factor) * qty;
    },
    blankForm() {
      return {
        id: '',
        status: '',
        code: '',
        startDate: '',
        endDate: '',
        total: 0,
        place: {
          id: '',
          name: '',
          bed: {
            id: '',
            name: '',
          },
        },
        patient: {
          id: '',
          name: '',
          birthDate: '',
          gender: '',
          identity: {
            type: 'cpf',
            value: '',
          },
        },
        insurance: {
          id: '',
          name: '',
          type: '',
          plan: {
            id: '',
            name: '',
          },
          record: '',
          validity: '',
        },
        items: [],
      };
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/scss/variables";
.account-data-page {
  .group-items {
    border: $border-width solid $border-color;
    border-radius: $border-radius;
    margin: $layout-spacing 0;
    padding: $layout-spacing;
  }
}
</style>
